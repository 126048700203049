<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Examination/TestPaperManage"
            >试卷管理 /
          </router-link>
          <span class="crumbs_item crumbs_last">自动组卷</span>
        </span>
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <div class="titleList">
        <ul>
          <li v-for="(item, index) in columns" :key="index">
            {{ item.title }}
          </li>
        </ul>
      </div>
      <!-- 单选区域 -->
      <div
        class="dataList"
        v-show="!item.deleted"
        v-for="(item, index) in radioList"
        :key="index + 'r'"
      >
        <span>单选题</span>
        <div class="pseudo" @click="showModal(index, radioList)">
          <span>
            {{ item.sectionId?FormatData(item.sectionId):'' }}
          </span>
        </div>
        <span style="margin-left: 17px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.simpleNum"
            :min="0"
            :max="item.redioNum?item.redioNum:0"
            @change="onChange"
          />题
        </span>
        <span style="margin-left: 26px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.commonlyNum"
            :min="0"
            :max="item.checkNum?item.checkNum:0"
            @change="onChange"
          />题
        </span>
        <span style="margin-left: 26px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.difficultyNum"
            :min="0"
            :max="item.isTrueNum?item.isTrueNum:0"
            @change="onChange"
          />题
        </span>
        <span class="L_span"
          >{{ item.simpleNum + item.commonlyNum + item.difficultyNum }}题</span
        >
        <span style="margin-left: 30px">
          <a-input-number
            id="inputNumber"
            v-model="item.score"
            :min="0"
            @change="onChange"
          />分
        </span>
        <span class="L_span" style="margin-left: 20px"
          >{{
            item.score *
            (item.simpleNum + item.commonlyNum + item.difficultyNum)
          }}分</span
        >
        <div style="margin-left: 30px">
          <span>
            <a v-if="index < 1" @click="addsubject(1)">新建</a>
            <a v-else @click="deletesubject(index, 1)">删除</a>
          </span>
        </div>
      </div>

      <!-- 多选区域 -->
      <div
        class="dataList"
        v-show="!item.deleted"
        v-for="(item, index) in checkboxList"
        :key="index + 'c'"
      >
        <span>多选题</span>
        <div class="pseudo" @click="showModal(index, checkboxList)">
          <span>
            {{ item.sectionId?FormatData(item.sectionId):'' }}
          </span>
        </div>
        <span style="margin-left: 17px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.simpleNum"
            :min="0"
            :max="item.redioNum?item.redioNum:0"
            @change="onChange"
          />题
        </span>
        <span style="margin-left: 26px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.commonlyNum"
            :min="0"
            :max="item.checkNum?item.checkNum:0"
            @change="onChange"
          />题
        </span>
        <span style="margin-left: 26px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.difficultyNum"
            :min="0"
            :max="item.isTrueNum?item.isTrueNum:0"
            @change="onChange"
          />题
        </span>
        <span class="L_span"
          >{{ item.simpleNum + item.commonlyNum + item.difficultyNum }}题</span
        >
        <span style="margin-left: 30px">
          <a-input-number
            id="inputNumber"
            v-model="item.score"
            :min="0"
            @change="onChange"
          />分
        </span>
        <span class="L_span" style="margin-left: 20px"
          >{{
            item.score *
            (item.simpleNum + item.commonlyNum + item.difficultyNum)
          }}分</span
        >
        <div style="margin-left: 30px">
          <span>
            <a v-if="index < 1" @click="addsubject(2)">新建</a>
            <a v-else @click="deletesubject(index, 2)">删除</a>
          </span>
        </div>
      </div>

      <!-- 判断区域 -->
      <div
        class="dataList"
        v-show="!item.deleted"
        v-for="(item, index) in judgeList"
        :key="index + 'j'"
      >
        <span>判断题</span>
        <div class="pseudo" @click="showModal(index, judgeList)">
          <span>
            {{ item.sectionId?FormatData(item.sectionId):'' }}
          </span>
        </div>
        <span style="margin-left: 17px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.simpleNum"
            :min="0"
            :max="item.redioNum?item.redioNum:0"
            @change="onChange"
          />题
        </span>
        <span style="margin-left: 26px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.commonlyNum"
            :min="0"
            :max="item.checkNum?item.checkNum:0"
            @change="onChange"
          />题
        </span>
        <span style="margin-left: 26px">
          共
          <a-input-number
            id="inputNumber"
            v-model="item.difficultyNum"
            :min="0"
            :max="item.isTrueNum?item.isTrueNum:0"
            @change="onChange"
          />题
        </span>
        <span class="L_span"
          >{{ item.simpleNum + item.commonlyNum + item.difficultyNum }}题</span
        >
        <span style="margin-left: 30px">
          <a-input-number
            id="inputNumber"
            v-model="item.score"
            :min="0"
            @change="onChange"
          />分
        </span>
        <span class="L_span" style="margin-left: 20px"
          >{{
            item.score *
            (item.simpleNum + item.commonlyNum + item.difficultyNum)
          }}分</span
        >
        <div style="margin-left: 30px">
          <span>
            <a v-if="index < 1" @click="addsubject(3)">新建</a>
            <a v-else @click="deletesubject(index, 3)">删除</a>
          </span>
        </div>
      </div>

      <div style="margin-top: 32px">
        <a-button type="primary" class="btn" @click="entervisible = true"
          >确认</a-button
        >
        <a-button type="primary" @click="$router.go(-1)" class="btn"
          >取消</a-button
        >
      </div>
    </div>
    <a-modal
      title="确认组卷"
      :width="372"
      :visible="entervisible"
      :confirm-loading="confirmLoading"
      @ok="submit"
      @cancel="entervisible = false"
    >
      <div>
        <div class="dlex">
          <span>试卷名称：</span>
          <a-input
            style="width: 179px"
            v-model="paperTitle"
            placeholder="请输入试卷名称"
          />
        </div>
        <div class="dlex">
          <span
            >该套试卷中，共有 <a>{{ totalNum }}</a> 道题，总分为
            <a>{{ totalGrade }}</a> 分</span
          >
        </div>
        <div class="dlex">
          <span>
            （单选题共 <a>{{ redioNum }}</a> 道，多选题共
            <a>{{ checkoutNum }}</a> 道，判断题共
            <a>{{ switchNum }}</a> 道）</span
          >
        </div>
      </div>
    </a-modal>
    <a-modal
      title="全部章节"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <!-- <a-radio-group name="radioGroup" v-model="checkedValues[0]" @change="onChange">
        <a-radio
          :value="item.sectionId"
          v-for="(item, index) in catalogue"
          :key="index"
        >
          {{ item.sectionName }}
        </a-radio>
      </a-radio-group> -->
      <div style="text-align:left">
        <a-tree
          @select="sectioncheck"
          :defaultExpandAll="true"
          :auto-expand-parent="true"
          :selected-keys="checkedValues"
          :tree-data="catalogue"
          :replaceFields="{children:'childList', title:'sectionName', key:'sectionId'}"
        />
      </div>
      <!-- <a-checkbox-group v-model="checkedValues" @change="onChange">
        <a-checkbox
          :value="item.sectionId"
          v-for="(item, index) in catalogue"
          :key="index"
        >
          {{ item.sectionName }}
        </a-checkbox>
      </a-checkbox-group> -->
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "题目类型",
    dataIndex: "key",
  },
  {
    title: "章节",
    dataIndex: "name",
  },
  {
    title: "简单",
    dataIndex: "age",
  },
  {
    title: "一般",
    dataIndex: "address",
  },
  {
    title: "困难",
    dataIndex: "class",
  },
  {
    title: "题数",
    dataIndex: "class",
  },
  {
    title: "单题得分",
    dataIndex: "class",
  },
  {
    title: "总分",
    dataIndex: "class",
  },
  {
    title: "操作",
    dataIndex: "class",
  },
];

import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      paperTitle: "", // 试卷名称
      entervisible: false, // 确认组卷弹框
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      columns,
      mode: null, // 组卷类型
      category: null, // 大类
      courseId: null, // 课程id
      paperId: null, // 组卷id
      status: null, // 是否启用状态
      radioList: [
        {
          deleted: 0, // 是否删除 1是 0否
          // paperId:3, // 组卷id
          sectionId: null, // 章节id
          type: 1, // 类型 1 单选 2多选 3判断
          score: 0, // 分数
          simpleNum: 0, // 简单题数
          commonlyNum: 0, // 一般题数
          difficultyNum: 0, // 困难题数
        },
      ],
      checkboxList: [
        {
          deleted: 0,
          // autoId:3,
          // paperId:3,
          sectionId: null,
          type: 2,
          score: 0,
          simpleNum: 0,
          commonlyNum: 0,
          difficultyNum: 0,
        },
      ],
      judgeList: [
        {
          deleted: 0,// 是否删除 1是 0否
          // autoId:3,
          // paperId:3,
          sectionId: null,
          type: 3,
          score: 0,
          simpleNum: 0,
          commonlyNum: 0,
          difficultyNum: 0,
        },
      ],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      value: 3,
      // 弹窗
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      catalogue: [],
      // 数组和位置
      array: null,
      iindex: null,
      // 当前显示数组
      checkedValues: [],
    };
  },
  // 事件处理器
  methods: {
    onChange(checkedValue) {
    //   this.checkedValues = [checkedValue];
    },
    handleChange(value) {
      // console.log(`selected ${value}`);
    },
    // 弹窗
    showModal(e, r) {
      this.checkedValues = [];
      this.array = r;
      this.iindex = e;
      let strarr = r[e].sectionId+'';
      // return;
      if (strarr != null) {
        strarr = strarr.split(",");
        this.checkedValues = strarr.map((item) => {
          return +item;
        });
      }
      this.visible = true;
    },
    // 关联章节选中
    sectioncheck(e){
      this.checkedValues = e;
    },
    handleOk(e) {
      // console.log(this.array[this.iindex]);
      // return;
      this.array[this.iindex].sectionId = this.checkedValues.join(",");
      this.getQuestionNum();
    },
    getQuestionNum(){
      this.$ajax({
        url:'/hxclass-management/exam/library/get-library/count',
        method:'get',
        params:{
          courseId: this.courseId, // 课程id
          sectionId: this.checkedValues.join(","), // 章节id
          type: this.array[0].type, // 类型 1.单选 2.多选 3.判断
        }
      }).then(res=>{
        if(res){
          this.$set(this.array[this.iindex],'redioNum',res.simpleCount)
          this.$set(this.array[this.iindex],'checkNum',res.commonlyCount)
          this.$set(this.array[this.iindex],'isTrueNum',res.sleepyCount)
          this.visible = false;
        }
      })
    },
    handleCancel(e) {
      // console.log("Clicked cancel button");
      this.visible = false;
    },
    
    FormatData(e){
      let str = ''
      let findName = (arr)=>{
        arr.forEach(element => {
          if(element.childList){
            findName(element.childList)
          }
          if(element.sectionId == e){
            str = str + element.sectionName + ' '
            return;
          }
        });
      }
      if(e){
        findName(this.catalogue)
      }
      return str
    },
    // 新建题目
    addsubject(e) {
      switch (e) {
        case 1:
          this.radioList.push({
            deleted: 0, // 是否删除 1是 0否
            // paperId:3, // 组卷id
            sectionId: "", // 章节id
            type: 1, // 类型 1 单选 2多选 3判断
            score: 0, // 分数
            simpleNum: 0, // 简单题数
            commonlyNum: 0, // 一般题数
            difficultyNum: 0, // 困难题数
          });
          this.array = this.radioList;
          this.iindex = this.radioList.length-1;
          this.getQuestionNum();
          break;
        case 2:
          this.checkboxList.push({
            deleted: 0, // 是否删除 1是 0否
            // paperId:3, // 组卷id
            sectionId: "", // 章节id
            type: 2, // 类型 1 单选 2多选 3判断
            score: 0, // 分数
            simpleNum: 0, // 简单题数
            commonlyNum: 0, // 一般题数
            difficultyNum: 0, // 困难题数
          });
          this.array = this.checkboxList;
          this.iindex = this.checkboxList.length-1;
          this.getQuestionNum();
          break;
        case 3:
          this.judgeList.push({
            deleted: 0, // 是否删除 1是 0否
            // paperId:3, // 组卷id
            sectionId: "", // 章节id
            type: 3, // 类型 1 单选 2多选 3判断
            score: 0, // 分数
            simpleNum: 0, // 简单题数
            commonlyNum: 0, // 一般题数
            difficultyNum: 0, // 困难题数
          });
          this.array = this.judgeList;
          this.iindex = this.judgeList.length-1;
          this.getQuestionNum();
          break;

        default:
          this.$message.error("系统错误");
          break;
      }
    },
    deletesubject(e, r) {
      switch (r) {
        case 1:
          this.radioList[e].deleted = 1;
          break;
        case 2:
          this.checkboxList[e].deleted = 1;
          break;
        case 3:
          this.judgeList[e].deleted = 1;
          break;

        default:
          this.$message.error("系统错误");
          break;
      }
      // this.templatedata.splice(e, 1);
    },

    // 自动组卷
    submit() {
      if (!this.paperTitle) {
        return this.$message.warning("请先填写试卷名称");
      }
      if(!this.totalNum) {
        return this.$message.warning("请先选择试题");
      }
      let arr = [];
      arr.push(...this.radioList, ...this.checkboxList, ...this.judgeList);
      arr = arr.map(item=>{
        let obj = {
          commonlyNum: item.commonlyNum,
          deleted: item.deleted,
          difficultyNum: item.difficultyNum,
          score: item.score,
          sectionId: item.sectionId,
          simpleNum: item.simpleNum,
          type: item.type
        }
        // if(item.isTrueNum){
        //   obj['isTrueNum'] = item.isTrueNum;
        // }
        // if(item.checkNum){
        //   obj['checkNum'] = item.checkNum;
        // }
        // if(item.redioNum){
        //   obj['redioNum'] = item.redioNum;
        // }
        return obj;
      })
      // console.log(arr)
      this.confirmLoading = true;
      this.$ajax({
        url: this.paperId
          ? "/hxclass-management/exam/group/edit"
          : "/hxclass-management/exam/group/save",
        method: this.paperId ? "put" : "post",
        params: {
          paperId: this.paperId, // 组卷id
          category: this.category, // 大类
          courseId: this.courseId, // 课程id
          status: 0, // 状态 1.启用 0.停用
          mode: 1, // 1.自动组卷 2.手动组卷
          name: this.paperTitle, //	组卷名称
          quesNum: this.totalNum, // 题数
          totalScore: this.totalGrade, // 总分
          autoJson: JSON.stringify(arr),
        },
      }).then((res) => {
        this.confirmLoading = false;
        if (res.code == 200 && res.success) {
          this.visible = false;
          this.$router.push("/admin/Examination/TestPaperManage");
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 获取组卷详情
    getPaperDetail() {
      this.$ajax({
        url: "/hxclass-management/exam/group/get",
        method: "get",
        params: {
          paperId: this.$route.query.paperId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.paperTitle = res.data.name;
          this.mode = res.data.mode;
          this.category = res.data.category;
          this.courseId = res.data.courseId;
          this.paperId = res.data.paperId;
          this.status = res.data.status;
          this.getPaperList();
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 获取章节目录
    getCatalogue() {
      this.$ajax({
        url: "/hxclass-management/course/tree",
        method: "get",
        params: {
          courseId: this.courseId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.catalogue = res.data;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 获取试题列表
    getPaperList() {
      this.$ajax({
        url: "/hxclass-management/exam/group/auto/paper/list",
        method: "get",
        params: {
          paperId: this.paperId,
        },
      }).then(async (res) => {
        if (res.code == 200 && res.success) {
          // 多选
          let checkboxlist = res.data.filter((item) => {
            return item.type == 2;
          });
          let arr = []
          for(let i=0;i<checkboxlist.length;i++){
            this.array = checkboxlist;
            this.iindex = i;
            this.checkedValues = [checkboxlist[i].sectionId];
            arr.push(this.$ajax({
              url:'/hxclass-management/exam/library/get-library/count',
              method:'get',
              params:{
                courseId: this.courseId, // 课程id
                sectionId: this.checkedValues.join(","), // 章节id
                type: this.array[0].type, // 类型 1.单选 2.多选 3.判断
              }
            }))
          }
          Promise.all(arr).then(res=>{
            checkboxlist.map((item,index)=>{
              item.redioNum = res[index].simpleCount
              item.checkNum = res[index].commonlyCount
              item.isTrueNum = res[index].sleepyCount
            })
            this.checkboxList = checkboxlist;
          })

          // setTimeout(()=>{
            // 单选
            let radiolist = res.data.filter((item) => {
              return item.type == 1;
            });
            let radioarr = [];
            radiolist.map((item,index)=>{
              this.array = radiolist;
              this.iindex = index;
              this.checkedValues = [item.sectionId];
              radioarr.push(this.$ajax({
                url:'/hxclass-management/exam/library/get-library/count',
                method:'get',
                params:{
                  courseId: this.courseId, // 课程id
                  sectionId: this.checkedValues.join(","), // 章节id
                  type: this.array[0].type, // 类型 1.单选 2.多选 3.判断
                }
              }))
            })
            Promise.all(radioarr).then(res=>{
              radiolist.map((item,index)=>{
                item.redioNum = res[index].simpleCount
                item.checkNum = res[index].commonlyCount
                item.isTrueNum = res[index].sleepyCount
              })
              this.radioList = radiolist;
            })
          // },1000)
          
          // setTimeout(()=>{
            // 判断
            let judgelist = res.data.filter((item) => {
              return item.type == 3;
            });
            let judgearr = [];
            judgelist.map((item,index)=>{
              this.array = judgelist;
              this.iindex = index;
              this.checkedValues = [item.sectionId];
              judgearr.push(this.$ajax({
                url:'/hxclass-management/exam/library/get-library/count',
                method:'get',
                params:{
                  courseId: this.courseId, // 课程id
                  sectionId: this.checkedValues.join(","), // 章节id
                  type: this.array[0].type, // 类型 1.单选 2.多选 3.判断
                }
              }))
            })
            Promise.all(judgearr).then(res=>{
              judgelist.map((item,index)=>{
                item.redioNum = res[index].simpleCount
                item.checkNum = res[index].commonlyCount
                item.isTrueNum = res[index].sleepyCount
              })
              this.$set(this,'judgeList',judgelist);
            })
          // },2000)
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  async created() {
    this.courseId = this.$route.query.courseId;
    if (this.$route.query.paperId) {
      this.getPaperDetail(); // 获取组卷详情
    } else {
      this.category = this.$route.query.category;
      this.radioList.map((item,index)=>{
        this.array = this.radioList;
        this.iindex = index;
        this.getQuestionNum();
      })
      setTimeout(()=>{
        this.checkboxList.map((item,index)=>{
          this.array = this.checkboxList;
          this.iindex = index;
          this.getQuestionNum();
        })
      },500)
      
      setTimeout(()=>{
        this.judgeList.map((item,index)=>{
          this.array = this.judgeList;
          this.iindex = index;
          this.getQuestionNum();
        })
      },1000)
    }
    this.getCatalogue();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    totalNum() {
      // 总数
      let num = 0;
      this.radioList.map((item, index) => {
        num += item.simpleNum + item.commonlyNum + item.difficultyNum;
      });
      this.checkboxList.map((item, index) => {
        num += item.simpleNum + item.commonlyNum + item.difficultyNum;
      });
      this.judgeList.map((item, index) => {
        num += item.simpleNum + item.commonlyNum + item.difficultyNum;
      });
      return num;
    },
    totalGrade() {
      // 总分
      let grade = 0;
      this.radioList.map((item, index) => {
        grade +=
          (item.simpleNum + item.commonlyNum + item.difficultyNum) * item.score;
      });
      this.checkboxList.map((item, index) => {
        grade +=
          (item.simpleNum + item.commonlyNum + item.difficultyNum) * item.score;
      });
      this.judgeList.map((item, index) => {
        grade +=
          (item.simpleNum + item.commonlyNum + item.difficultyNum) * item.score;
      });
      return grade;
    },
    redioNum() {
      // 单选
      let num = 0;
      this.radioList.map((item, index) => {
        num += item.simpleNum + item.commonlyNum + item.difficultyNum;
      });
      return num;
    },
    checkoutNum() {
      // 多选
      let num = 0;
      this.checkboxList.map((item, index) => {
        num += item.simpleNum + item.commonlyNum + item.difficultyNum;
      });
      return num;
    },
    switchNum() {
      // 判断
      let num = 0;
      this.judgeList.map((item, index) => {
        num += item.simpleNum + item.commonlyNum + item.difficultyNum;
      });
      return num;
    },
  },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.sider_right {
  display: flex;
  .ant-input {
    margin-left: 20px;
  }
}
.titleList {
  width: 1200px;
  height: 44px;
  background: #f6f6fc;
  padding: 0 43px;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  border: 1px solid #eeeeee;
  ul {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      float: left;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 16px;
    }
  }
}
.dataList {
  width: 1200px;
  height: 44px;
  padding-left: 43px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  font-family: PingFang SC;
  color: #333333;
  .ant-input-number {
    width: 70px;
  }
  .pseudo {
    width: 143px;
    height: 29px;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    border: 1px solid #c4c4c4;
    margin-left: 60px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 29px;
    }
  }
  .L_span {
    width: 160px;
    text-align: center;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
